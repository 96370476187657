import { useState, useLayoutEffect } from 'react';
import debounce from 'lodash/debounce';

interface ScrollObject {
  x: number;
  y: number;
  direction: 'up' | 'down';
}

interface UseScroll {
  (delay?: number): ScrollObject;
}

const useScroll: UseScroll = (delay = 200) => {
  const isBrowser = typeof window !== 'undefined';
  const [scroll, setScroll] = useState<ScrollObject>(() => ({
    x: isBrowser ? window.pageXOffset : 0,
    y: isBrowser ? window.pageYOffset : 0,
    direction: 'down',
  }));

  useLayoutEffect(() => {
    const listener = () => {
      setScroll(prev => ({
        x: window.pageXOffset,
        y: window.pageYOffset,
        direction: prev.y > window.pageYOffset ? 'up' : 'down',
      }));
    };

    window.addEventListener('scroll', debounce(listener, delay), { passive: true, capture: false });

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return scroll;
};

export default useScroll;
